<template>
    <div class="pager-outer-wrap">
        <div class="pager-wrap">
            <ul class="pager prev-nav" v-if="currentPage > 1 && totalPage > 1">
                <li>
                    <!--<router-link :to="`${link}?page=1${paramSet()}`"><img src="../../../images/paginator/pager-first.png"></router-link>-->
                    <div @click="movePage(1)" >
                        <img src="../../assets/images/paginator/pager-first.png">
                    </div>
                </li>
                <li>
                    <!--<router-link :to="`${link}?page=${currentPage-1}${paramSet()}`">
                        <img src="../../../images/paginator/pager-previous.png">
                    </router-link>-->
                    <div @click="movePage(currentPage-1)" >
                        <img src="../../assets/images/paginator/pager-previous.png">
                    </div>
                </li>
            </ul>
            <ul class="pager pc-tablet">
                <li :class="{select: Number(value) === Number(currentPage)}" v-for="(value, index) in pcPageList" :key="index">
                    <!--<router-link :to="`${link}?page=${value}${paramSet()}`">{{value}}</router-link>-->
                    <div @click="movePage(value)">{{value}}</div>
                </li>
            </ul>
            <ul class="pager only-s-mobile">
                <li :class="{select: Number(value) === Number(currentPage)}" v-for="(value, index) in mobilePageList" :key="index">
                    <!--<router-link :to="`${link}?page=${value}${paramSet()}`">{{value}}</router-link>-->
                    <div @click="movePage(value)">{{value}}</div>
                </li>
            </ul>
            <ul class="pager next-nav" v-if="totalPage > currentPage">
                <li>
                    <!--<router-link :to="`${link}?page=${currentPage+1}${paramSet()}`">
                        <img src="../../../images/paginator/pager-next.png">
                    </router-link>-->

                    <div @click="movePage(currentPage+1)" >
                        <img src="../../assets/images/paginator/pager-next.png">
                    </div>
                </li>
                <li>
                    <!--<router-link :to="`${link}?page=${totalPage}${paramSet()}`">
                        <img src="../../../images/paginator/pager-last.png">
                    </router-link>-->

                    <div @click="movePage(totalPage)">
                        <img src="../../assets/images/paginator/pager-last.png">
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            current_page: Number,
            total_page: Number,
        },
        data() {
            return {
                currentPage: this.current_page,
                totalPage: this.total_page,
                pcPageList: [],
                mobilePageList: []
            }
        },
        methods: {
            updatePageList () {
                let pcStartPage = this.currentPage;
                let pcLastPage = 7;
                let mobileStartPage = this.currentPage;
                let mobileLastPage = 5;
                if (this.totalPage <= 7) {
                    pcStartPage = 1
                }
                if (this.totalPage <= 5) {
                    mobileStartPage = 1
                }
                if (this.currentPage > 3 && this.totalPage > 7) {
                    pcStartPage = this.currentPage - 3
                    pcLastPage = this.currentPage + 3
                    if (pcLastPage >= this.totalPage) {
                        pcLastPage = this.totalPage
                    }
                    if (pcLastPage - pcStartPage < 7) {
                        pcStartPage = pcLastPage - 6
                    }
                }

                if (pcLastPage <= 7) {
                    pcStartPage = 1
                }
                if (this.totalPage <= 7) {
                    pcLastPage = this.totalPage
                }

                if (this.currentPage > 2 && this.totalPage > 5) {
                    mobileStartPage = this.currentPage - 2
                    mobileLastPage = this.currentPage + 2
                    if (mobileLastPage >= this.totalPage) {
                        mobileLastPage = this.totalPage
                    }
                    if (mobileLastPage - mobileStartPage < 5) {
                        mobileStartPage = mobileLastPage - 4
                    }
                }
                if (mobileLastPage <= 5) {
                    mobileStartPage = 1
                }
                if (this.totalPage <= 5) {
                    mobileLastPage = this.totalPage
                }
                for (let i = pcStartPage; i <= pcLastPage; i++) {
                    this.pcPageList.push(i)
                }
                for (let i = mobileStartPage; i <= mobileLastPage; i++) {
                    this.mobilePageList.push(i)
                }
            },
            movePage(page) {
                this.$router.push({
                    query: {...this.$route.query, page: page}
                }).catch(() => {})
            },
            /*paramSet() {
                let query = this.$route.query
                if(query) {
                    let queryKeyList = Object.keys(query)
                    let queryString = '&'
                    queryKeyList.forEach(key => {
                        if(key !== 'page') {
                            queryString = queryString+key+'='+query[key]+'&'
                        }
                    })
                    queryString = queryString.slice(0,-1)
                    return queryString

                } else {
                    return ''
                }
            }*/
        },
        created() {
            this.updatePageList()
        },
        mounted() {
        },
        updated() {
        }
    }
</script>
